import { Box, Container, Grid, Typography } from "@mui/material";
import { GiftItemDisplay, GiftMessageDisplay } from "./Gifts";

import { CartContext } from "../context/CartContext";
import ExpandableText from "./ExpandableText";
import { QuantitySelector } from "./QuantitySelector";
import { centsToCurrency } from "../services/firebase";
import { useContext } from "react";

export const Cart = () => {
  const {
    cartItems,
    removeFromCart,
    addToCart,
    discount,
    getCartDiscount,
    getCartTotal,
    getCartSubtotal,
    getCartItems,
  } = useContext(CartContext);

  if (cartItems.length === 0) {
    return (
      <Container sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom>
          Your Cart
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your cart is empty.
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mb: 2, mt: 1 }}>
      <Box>
        {getCartItems().map((item) => (
          <Box key={item.sku} sx={{ pb: 1 }}>
            <Grid container>
              <Grid item xs={8} sx={{ pr: 1 }}>
                <Typography variant="h6">
                  {item.name}
                  {item.giftMessage && <GiftItemDisplay />}
                </Typography>
                {item.description && <ExpandableText text={item.description} />}

                {item.discountedAmount !== item.amount && (
                  <Typography variant="body2" color="secondary">
                    <s> {centsToCurrency(item.amount)} </s>
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary">
                  {centsToCurrency(item.discountedAmount)}
                </Typography>
                {item.selectedOptions?.length > 0 && (
                  <Typography variant="body2" color="textSecondary">
                    {item.selectedOptions.join(", ")}
                  </Typography>
                )}
                {item.giftMessage && (
                  <GiftMessageDisplay message={item.giftMessage} />
                )}
              </Grid>
              <Grid item xs={1}>
                <QuantitySelector
                  item={item}
                  remove={removeFromCart}
                  add={addToCart}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" align="right" sx={{ mt: 2 }}>
                  {centsToCurrency(item.quantity * item.discountedAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
        <hr />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography variant="body1" mr={3}>
            {discount ? "Subtotal:" : "Total:"}
          </Typography>
          <Typography variant="body1" color="success">
            {centsToCurrency(getCartSubtotal())}
          </Typography>
        </Box>
        {discount && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body2" mr={3}>
                Discount:{" "}
                <Typography variant="overline">{discount.code}</Typography>
              </Typography>
              <Typography variant="body2" color="error">
                -{centsToCurrency(getCartDiscount())}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography variant="body1" mr={3}>
                Total:{" "}
              </Typography>
              <Typography variant="body1" color="success">
                {centsToCurrency(getCartTotal())}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};
