import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { DayPass } from "../components/offer/DayPass";
import { Header } from "../components/header/Header";
import Paper from "@mui/material/Paper";
import { Passes } from "../components/Passes";
import { Products } from "../components/Products";
import SocialMetaTags from "../components/header/SocialMetaTags";

const Home = () => {
  return (
    <>
      <SocialMetaTags title="Welcome to The Cola St. Louis" />
      <Header />
      <Container spacing={2}>
        <Box sx={{ mb: 2 }}></Box>
        <DayPass />
        <Paper elevation={6} sx={{ p: 2, mt: 2, mb: 2 }}>
          <Passes />
        </Paper>
        <Paper elevation={6} sx={{ p: 2, mb: 2 }}>
          <Products />
        </Paper>
      </Container>
    </>
  );
};

export default Home;
