import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const countryOptions = [
  { code: 'US', name: 'United States' },
  { code: 'CA', name: 'Canada' }
];

const stateOptions = [
  { code: 'MO', name: 'Missouri' },
  { code: 'IL', name: 'Illinois' }
  // ... other states
];

const ShippingAddressManager = ({ cartItems, onShippingUpdate }) => {
  const [shippingGroups, setShippingGroups] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [errors, setErrors] = useState({});

  const unassignedItems = cartItems.filter(item => 
    !shippingGroups.some(group => group.items.some(groupItem => groupItem.sku === item.sku))
  );

  const validateForm = (formData) => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.state) newErrors.state = 'State is required';
    if (!formData.zip) newErrors.zip = 'ZIP code is required';
    if (!formData.country) newErrors.country = 'Country is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddAddress = () => {
    if (selectedItems.length === 0) {
      setErrors({ items: 'Please select at least one item to ship' });
      return;
    }

    if (!validateForm(currentAddress)) return;

    const newGroup = {
      address: currentAddress,
      items: cartItems.filter(item => selectedItems.includes(item.sku))
    };

    setShippingGroups([...shippingGroups, newGroup]);
    setCurrentAddress(null);
    setSelectedItems([]);
    setIsEditing(false);
    
    onShippingUpdate([...shippingGroups, newGroup]);
  };

  const handleEditAddress = (index) => {
    const group = shippingGroups[index];
    setCurrentAddress(group.address);
    setSelectedItems(group.items.map(item => item.sku));
    setShippingGroups(shippingGroups.filter((_, i) => i !== index));
    setIsEditing(true);
    onShippingUpdate(shippingGroups.filter((_, i) => i !== index));
  };

  const handleRemoveAddress = (index) => {
    const newGroups = shippingGroups.filter((_, i) => i !== index);
    setShippingGroups(newGroups);
    onShippingUpdate(newGroups);
  };

  const handleItemSelection = (sku) => {
    setSelectedItems(prev => 
      prev.includes(sku) 
        ? prev.filter(item => item !== sku)
        : [...prev, sku]
    );
    setErrors({ ...errors, items: undefined });
  };

  return (
    <Box>
      <Button
        onClick={() => setExpanded(!expanded)}
        startIcon={expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      >
        Shipping Details
      </Button>

      <Collapse in={expanded}>
        {shippingGroups.map((group, index) => (
          <Paper key={index} elevation={2} sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Box>
                <Typography variant="subtitle1">{group.address.name}</Typography>
                <Typography variant="body2">{group.address.address}</Typography>
                <Typography variant="body2">
                  {group.address.city}, {group.address.state} {group.address.zip}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Shipping items:
                </Typography>
                {group.items.map(item => (
                  <Typography key={item.sku} variant="body2" color="text.secondary">
                    • {item.name}
                  </Typography>
                ))}
              </Box>
              <Box>
                <IconButton onClick={() => handleEditAddress(index)} size="small">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleRemoveAddress(index)} size="small">
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        ))}

        {(unassignedItems.length > 0 || isEditing) && (
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {isEditing ? 'Edit Shipping Address' : 'Add Shipping Address'}
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Select items to ship to this address:
              </Typography>
              {unassignedItems.map(item => (
                <FormControlLabel
                  key={item.sku}
                  control={
                    <Checkbox
                      checked={selectedItems.includes(item.sku)}
                      onChange={() => handleItemSelection(item.sku)}
                    />
                  }
                  label={item.name}
                />
              ))}
              {errors.items && (
                <Typography color="error" variant="caption" display="block">
                  {errors.items}
                </Typography>
              )}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Full Name"
                  value={currentAddress?.name || ''}
                  onChange={(e) => setCurrentAddress(prev => ({ ...prev, name: e.target.value }))}
                  error={!!errors.name}
                  helperText={errors.name}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  value={currentAddress?.address || ''}
                  onChange={(e) => setCurrentAddress(prev => ({ ...prev, address: e.target.value }))}
                  error={!!errors.address}
                  helperText={errors.address}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="City"
                  value={currentAddress?.city || ''}
                  onChange={(e) => setCurrentAddress(prev => ({ ...prev, city: e.target.value }))}
                  error={!!errors.city}
                  helperText={errors.city}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth error={!!errors.state} required>
                  <InputLabel>State</InputLabel>
                  <Select
                    value={currentAddress?.state || ''}
                    onChange={(e) => setCurrentAddress(prev => ({ ...prev, state: e.target.value }))}
                    label="State"
                  >
                    {stateOptions.map(state => (
                      <MenuItem key={state.code} value={state.code}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="ZIP Code"
                  value={currentAddress?.zip || ''}
                  onChange={(e) => setCurrentAddress(prev => ({ ...prev, zip: e.target.value }))}
                  error={!!errors.zip}
                  helperText={errors.zip}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Country</InputLabel>
                  <Select
                    value={currentAddress?.country || ''}
                    onChange={(e) => setCurrentAddress(prev => ({ ...prev, country: e.target.value }))}
                    label="Country"
                  >
                    {countryOptions.map(country => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <Button onClick={() => {
                setCurrentAddress(null);
                setSelectedItems([]);
                setIsEditing(false);
              }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleAddAddress}>
                {isEditing ? 'Update Address' : 'Add Address'}
              </Button>
            </Box>
          </Paper>
        )}
      </Collapse>
    </Box>
  );
};

export default ShippingAddressManager;
