import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import BudBarInquiryForm from "../components/BudBarInquiry";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalBarIcon from "@mui/icons-material/LocalBar";
// Import the meta tags component for SEO
import SocialMetaTags from "../components/header/SocialMetaTags";
import { useNavigate } from "react-router-dom";

const MobileBudBar = () => {
  const navigate = useNavigate();
  const [inquiryFormOpen, setInquiryFormOpen] = useState(false);

  const handleOpenInquiryForm = () => {
    setInquiryFormOpen(true);
  };

  const handleCloseInquiryForm = () => {
    setInquiryFormOpen(false);
  };

  const features = [
    {
      title: "Professional Service",
      description: "Experienced budtenders and mixologists for your event",
      icon: <LocalBarIcon color="primary" />,
    },
    {
      title: "Custom Experience",
      description: "Tailored menu and setup to match your event theme",
      icon: <CelebrationIcon color="primary" />,
    },
    {
      title: "Full Event Support",
      description: "From setup to cleanup, we handle everything",
      icon: <GroupsIcon color="primary" />,
    },
  ];

  const weekdayPricing = {
    single: [
      { hours: 2, price: 240 },
      { hours: 3, price: 340 },
      { hours: 4, price: 460 },
      { hours: 8, price: 900 },
    ],
    double: [
      { hours: 2, price: 360 },
      { hours: 3, price: 520 },
      { hours: 4, price: 700 },
      { hours: 8, price: 1380 },
    ],
  };

  const weekendPricing = {
    single: [
      { hours: 2, price: 280 },
      { hours: 3, price: 400 },
      { hours: 4, price: 520 },
      { hours: 8, price: 1020 },
    ],
    double: [
      { hours: 2, price: 420 },
      { hours: 3, price: 610 },
      { hours: 4, price: 800 },
      { hours: 8, price: 1580 },
    ],
  };

  const cannabisProducts = [
    "Flower",
    "Pre Rolls",
    "Edibles",
    "Concentrates (for vaping)",
    "Infused beverages",
  ];

  const additionalServices = [
    "Brand product demos",
    "Custom party favors (pipes, rolling trays, doob tubes, papers, matches, gift bags)",
    "Professional Catering",
    "Professional photography, Video Live stream and/or recording",
    "Master of ceremonies, DJ additional live entertainment",
    "Hookah Service",
    "Live Hoop, Fire, Aerial Arts",
    "Live Magician",
    "Live Comedy",
    "LiveMusic",
  ];

  const policies = [
    "CONSULTATION INCLUDED (D9 THC PRODUCT REFERRALS AVAILABLE)",
    "1 HOUR OF SETUP AND BREAKDOWN INCLUDED",
    "50% DEPOSIT REQUIRED AT TIME OF BOOKING",
    "2 HOUR RENTAL MINIMUM",
    "TRAVEL FEE INCLUDED WITHIN 40 MILES OF ST LOUIS CITY ($150/50 MILES AFTER)",
  ];

  return (
    <>
      <SocialMetaTags
        title="Mobile Bud Bar Services - The Cola St. Louis"
        description="Elevate your event with our premium mobile budbar experience. Professional budtenders, custom menus, and full event support."
        image="/cola-stl.svg"
        page="mobile-bud-bar"
      />

      <Container maxWidth="lg" sx={{ py: 6 }}>
        {/* Hero Section */}
        <Box sx={{ mb: 8, textAlign: "center" }}>
          <Typography variant="h3" component="h1" gutterBottom>
            Mobile Bud Bar Services
          </Typography>
          <Typography variant="h5" color="text.secondary" paragraph>
            Elevate Your Event with Our Premium Mobile Bud Bar Experience
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleOpenInquiryForm}
            sx={{ mt: 2 }}
          >
            Book Your Event
          </Button>
        </Box>

        {/* Features Section */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                  <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography>{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Image Section */}
        <Box sx={{ mb: 8 }}>
          <CardMedia
            component="img"
            height="400"
            image="/api/placeholder/1200/400"
            alt="Mobile Bud Bar Setup"
            sx={{ borderRadius: 2 }}
          />
        </Box>

        {/* Pricing Section */}
        <Box sx={{ mb: 8 }}>
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            textAlign="center"
            sx={{ color: "primary.main", fontWeight: "bold", mb: 4 }}
          >
            PRICING OPTIONS
          </Typography>

          {/* Weekday Pricing */}
          <Typography
            variant="h4"
            gutterBottom
            textAlign="center"
            sx={{ mb: 3 }}
          >
            WEEKDAY EVENT
          </Typography>
          <Grid container spacing={4} sx={{ mb: 6 }}>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper} elevation={3}>
                <Typography
                  variant="h6"
                  sx={{ p: 2, bgcolor: "primary.main", color: "white" }}
                >
                  1 BUDBAR ATTENDANT
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ p: 1, bgcolor: "grey.100" }}
                >
                  SERVES ABOUT 35 GUESTS PER HOUR
                </Typography>
                <Table>
                  <TableBody>
                    {weekdayPricing.single.map((row) => (
                      <TableRow key={row.hours}>
                        <TableCell>{row.hours} hours</TableCell>
                        <TableCell align="right">${row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper} elevation={3}>
                <Typography
                  variant="h6"
                  sx={{ p: 2, bgcolor: "primary.main", color: "white" }}
                >
                  2 BUDBAR ATTENDANTS
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ p: 1, bgcolor: "grey.100" }}
                >
                  SERVES ABOUT 75 GUESTS PER HOUR
                </Typography>
                <Table>
                  <TableBody>
                    {weekdayPricing.double.map((row) => (
                      <TableRow key={row.hours}>
                        <TableCell>{row.hours} hours</TableCell>
                        <TableCell align="right">${row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* Weekend Pricing */}
          <Typography
            variant="h4"
            gutterBottom
            textAlign="center"
            sx={{ mb: 3 }}
          >
            WEEKEND EVENT
          </Typography>
          <Grid container spacing={4} sx={{ mb: 6 }}>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper} elevation={3}>
                <Typography
                  variant="h6"
                  sx={{ p: 2, bgcolor: "primary.main", color: "white" }}
                >
                  1 BUDBAR ATTENDANT
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ p: 1, bgcolor: "grey.100" }}
                >
                  SERVES ABOUT 35 GUESTS PER HOUR
                </Typography>
                <Table>
                  <TableBody>
                    {weekendPricing.single.map((row) => (
                      <TableRow key={row.hours}>
                        <TableCell>{row.hours} hours</TableCell>
                        <TableCell align="right">${row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper} elevation={3}>
                <Typography
                  variant="h6"
                  sx={{ p: 2, bgcolor: "primary.main", color: "white" }}
                >
                  2 BUDBAR ATTENDANTS
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ p: 1, bgcolor: "grey.100" }}
                >
                  SERVES ABOUT 75 GUESTS PER HOUR
                </Typography>
                <Table>
                  <TableBody>
                    {weekendPricing.double.map((row) => (
                      <TableRow key={row.hours}>
                        <TableCell>{row.hours} hours</TableCell>
                        <TableCell align="right">${row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {/* Add-ons Section */}
          <Typography
            variant="h4"
            gutterBottom
            textAlign="center"
            sx={{ mb: 3 }}
          >
            ADD-ONS
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card elevation={3}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "primary.main" }}
                  >
                    PRODUCTS
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    STLCC MOBILE BUDBAR CAN OFFER NON D9 THC PRODUCTS TO
                    PURCHASE. ALL PRODUCTS LISTED ARE AVAILABLE IN CBD, D8, AND
                    CBG. INQUIRE FOR PRICING
                  </Typography>
                  <List>
                    {cannabisProducts.map((product, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={product} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card elevation={3}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ color: "primary.main" }}
                  >
                    ADDITIONAL SERVICES
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    TAKE YOUR EVENT TO THE NEXT LEVEL
                  </Typography>
                  <List>
                    {additionalServices.map((service, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={service} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Policies Section */}
        <Box sx={{ mb: 8 }}>
          <Card elevation={3}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: "primary.main" }}
              >
                IMPORTANT INFORMATION
              </Typography>
              <List>
                {policies.map((policy, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={policy} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Box>

        {/* CTA Section */}
        <Box
          sx={{
            textAlign: "center",
            py: 4,
            bgcolor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Ready to Elevate Your Event?
          </Typography>
          <Typography variant="body1" paragraph sx={{ mb: 3 }}>
            Contact us to discuss how we can make your event extraordinary
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleOpenInquiryForm}
          >
            Get Started
          </Button>
        </Box>
        <BudBarInquiryForm
          open={inquiryFormOpen}
          onClose={handleCloseInquiryForm}
        />
      </Container>
    </>
  );
};

export default MobileBudBar;
