import { Facebook, Instagram, Language, YouTube } from "@material-ui/icons";

const TikTokIcon = ({ color = "action" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="20px"
      height="20px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

export const SocialMediaCell = ({ signup }) => {
  const getSocialUrl = (platform, handle) => {
    const urls = {
      facebook: `https://facebook.com/${handle}`,
      instagram: `https://instagram.com/${handle.replace("@", "")}`,
      tiktok: `https://tiktok.com/@${handle.replace("@", "")}`,
      youtube: handle.includes("http")
        ? handle
        : `https://youtube.com/${handle}`,
      other: handle.includes("http") ? handle : `https://${handle}`,
    };
    return urls[platform];
  };

  return (
    <div className="space-y-2">
      <div className="text-sm font-medium text-gray-900">{signup.email}</div>

      {signup.facebook && (
        <p>
          <a
            href={getSocialUrl("facebook", signup.facebook)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600 transition-colors"
          >
            <Facebook className="w-4 h-4" />
            <span>{signup.facebook}</span>
          </a>
        </p>
      )}

      {signup.instagram && (
        <p>
          <a
            href={getSocialUrl("instagram", signup.instagram)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-pink-600 transition-colors"
          >
            <Instagram className="w-4 h-4" />
            <span>{signup.instagram}</span>
          </a>
        </p>
      )}

      {signup.tiktok && (
        <p>
          <a
            href={getSocialUrl("tiktok", signup.tiktok)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-black transition-colors"
          >
            <TikTokIcon className="w-4 h-4" />
            <span>{signup.tiktok}</span>
          </a>
        </p>
      )}

      {signup.youtube && (
        <p>
          <a
            href={getSocialUrl("youtube", signup.youtube)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-red-600 transition-colors"
          >
            <YouTube className="w-4 h-4" />
            <span>{signup.youtube}</span>
          </a>
        </p>
      )}

      {signup.otherSocial && (
        <p>
          <a
            href={getSocialUrl("other", signup.otherSocial)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
          >
            <Language className="w-4 h-4" />
            <span>{signup.otherSocial}</span>
          </a>
        </p>
      )}
    </div>
  );
};

export default SocialMediaCell;
