import { Box, Divider, Paper, TextField, Typography } from "@mui/material";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import React from "react";

export const GiftMessageInput = ({ giftMessage, onChange, error }) => (
  <TextField
    fullWidth
    multiline
    rows={3}
    label="Gift Message"
    value={giftMessage}
    onChange={(e) => onChange(e.target.value)}
    error={!!error}
    helperText={error}
    placeholder="Add a personal message to be included with your gift..."
    sx={{ mb: 2 }}
  />
);

export const GiftMessageDisplay = ({ message }) => (
  <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.50", mb: 2 }}>
    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
      <CardGiftcardIcon color="primary" sx={{ mr: 1 }} />
      <Typography variant="subtitle1" color="primary">
        Gift Message
      </Typography>
    </Box>
    <Divider sx={{ my: 1 }} />
    <Typography
      variant="body2"
      sx={{ whiteSpace: "pre-line", fontStyle: "italic" }}
    >
      {message}
    </Typography>
  </Paper>
);

export const GiftItemDisplay = ({ item }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <CardGiftcardIcon fontSize="small" sx={{ mr: 1, color: "primary.main" }} />
    <Typography variant="caption" color="primary">
      Gift Item
    </Typography>
  </Box>
);
