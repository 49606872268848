import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import React, { useState } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const initialFormState = {
  name: "",
  email: "",
  phone: "",
  eventDate: null,
  eventTime: null,
  duration: "",
  guestCount: "",
  location: "",
  eventType: "",
  additionalServices: [],
  message: "",
};

const BudBarInquiryForm = ({ open, onClose }) => {
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const availableDurations = [2, 3, 4, 8];
  const eventTypes = [
    "Wedding",
    "Corporate Event",
    "Birthday Party",
    "Private Party",
    "Festival/Concert",
    "Other",
  ];

  const additionalServiceOptions = [
    "Brand product demos",
    "Custom party favors",
    "Professional Catering",
    "Professional photography/video",
    "DJ/Live entertainment",
    "Hookah Service",
    "Live Performance Art",
    "Other",
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when field is edited
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      eventDate: date,
    }));
    if (errors.eventDate) {
      setErrors((prev) => ({ ...prev, eventDate: null }));
    }
  };

  const handleTimeChange = (time) => {
    setFormData((prev) => ({
      ...prev,
      eventTime: time,
    }));
    if (errors.eventTime) {
      setErrors((prev) => ({ ...prev, eventTime: null }));
    }
  };

  const handleServiceChange = (service) => {
    setFormData((prev) => ({
      ...prev,
      additionalServices: prev.additionalServices.includes(service)
        ? prev.additionalServices.filter((s) => s !== service)
        : [...prev.additionalServices, service],
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.eventDate) newErrors.eventDate = "Event date is required";
    if (!formData.eventTime) newErrors.eventTime = "Event time is required";
    if (!formData.duration) newErrors.duration = "Duration is required";
    if (!formData.guestCount) newErrors.guestCount = "Guest count is required";
    if (!formData.location) newErrors.location = "Location is required";
    if (!formData.eventType) newErrors.eventType = "Event type is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setSubmitting(true);
    try {
      const response = await fetch(
        "https://us-central1-manageyourclub-973b2.cloudfunctions.net/submitBudBarInquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();

      if (!result.success) {
        throw new Error(result.error || "Failed to submit inquiry");
      }

      setSubmitSuccess(true);

      // Reset form after successful submission
      setTimeout(() => {
        setFormData(initialFormState);
        onClose();
        setSubmitSuccess(false);
      }, 2000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setFormData(initialFormState);
    setErrors({});
    setSubmitSuccess(false);
    setSubmitError(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5" component="div" gutterBottom>
          Request Bud Bar Services
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Fill out the form below and we'll get back to you within 24 hours
        </Typography>
      </DialogTitle>

      <DialogContent>
        {submitSuccess && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Thank you for your inquiry! We'll be in touch soon.
          </Alert>
        )}

        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            There was an error submitting your request. Please try again.
          </Alert>
        )}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label="Full Name"
                fullWidth
                required
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                label="Email"
                type="email"
                fullWidth
                required
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="phone"
                label="Phone Number"
                fullWidth
                required
                value={formData.phone}
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Event Date"
                value={formData.eventDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    error={!!errors.eventDate}
                    helperText={errors.eventDate}
                  />
                )}
                disablePast
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TimePicker
                label="Event Time"
                value={formData.eventTime}
                onChange={handleTimeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    error={!!errors.eventTime}
                    helperText={errors.eventTime}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required error={!!errors.duration}>
                <InputLabel>Duration</InputLabel>
                <Select
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  label="Duration"
                >
                  {availableDurations.map((hours) => (
                    <MenuItem key={hours} value={hours}>
                      {hours} Hours
                    </MenuItem>
                  ))}
                </Select>
                {errors.duration && (
                  <FormHelperText>{errors.duration}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="guestCount"
                label="Estimated Guest Count"
                type="number"
                fullWidth
                required
                value={formData.guestCount}
                onChange={handleChange}
                error={!!errors.guestCount}
                helperText={errors.guestCount}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required error={!!errors.eventType}>
                <InputLabel>Event Type</InputLabel>
                <Select
                  name="eventType"
                  value={formData.eventType}
                  onChange={handleChange}
                  label="Event Type"
                >
                  {eventTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                {errors.eventType && (
                  <FormHelperText>{errors.eventType}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="location"
                label="Event Location"
                fullWidth
                required
                value={formData.location}
                onChange={handleChange}
                error={!!errors.location}
                helperText={errors.location}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Additional Services Interested In
              </Typography>
              <Grid container spacing={1}>
                {additionalServiceOptions.map((service) => (
                  <Grid item xs={12} sm={6} key={service}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.additionalServices.includes(
                            service
                          )}
                          onChange={() => handleServiceChange(service)}
                        />
                      }
                      label={service}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="message"
                label="Additional Information"
                multiline
                rows={4}
                fullWidth
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us more about your event and any special requests..."
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
        >
          {submitting ? "Submitting..." : "Submit Inquiry"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BudBarInquiryForm;
