import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getOpenMicSignups,
  removePerformer,
  sendPerformerEmails,
  updatePerformerStatus,
} from "../../services/firebase";

import EmailConfirmationModal from "./EmailConfirmationModal";
import SocialMediaCell from "./SocialMediaCell";

const OpenMicAdmin = () => {
  const [signups, setSignups] = useState([]);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    const fetchSignups = async () => {
      const data = await getOpenMicSignups();
      data.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      setSignups(data);
    };
    fetchSignups();
  }, []);

  const handleStatusChange = async (id, selected) => {
    await updatePerformerStatus(id, selected);
    setSignups(
      signups.map((signup) =>
        signup.id === id ? { ...signup, selected } : signup
      )
    );
  };

  const handleRemove = async (id) => {
    if (window.confirm("Are you sure you want to remove this performer?")) {
      await removePerformer(id);
      setSignups(signups.filter((signup) => signup.id !== id));
    }
  };

  const handleSendEmails = async (emailData) => {
    try {
      const emailsSent = await sendPerformerEmails(emailData);
      console.log({ emailsSent });
      setSnackbar({
        open: true,
        message: "Emails sent successfully",
        severity: "success",
      });
      setIsEmailModalOpen(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to send emails",
        severity: "error",
      });
    }
  };

  const selectedPerformers = signups.filter((signup) => signup.selected);

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h4">Open Mic Admin</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsEmailModalOpen(true)}
          disabled={selectedPerformers.length === 0}
        >
          Send Emails to Performers ({selectedPerformers.length})
        </Button>
      </Box>

      <EmailConfirmationModal
        open={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        selectedPerformers={selectedPerformers}
        onRemovePerformer={(id) => handleStatusChange(id, false)}
        onSendEmails={handleSendEmails}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Stage Name</TableCell>
              <TableCell>Email & Social</TableCell>
              <TableCell>Sign Up Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signups.map((signup, index) => (
              <TableRow key={signup.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{signup.name}</TableCell>
                <TableCell>{signup.stageName}</TableCell>
                <TableCell>
                  <SocialMediaCell signup={signup} />
                </TableCell>
                <TableCell>
                  {signup.createdAt.toDate().toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <Button
                    fullWidth
                    variant={signup.selected ? "contained" : "outlined"}
                    onClick={() =>
                      handleStatusChange(signup.id, !signup.selected)
                    }
                  >
                    {signup.selected ? "✅ Selected" : "Select"}
                  </Button>
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => handleRemove(signup.id)}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default OpenMicAdmin;
