import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

const EmailConfirmationModal = ({
  open,
  onClose,
  selectedPerformers,
  onRemovePerformer,
  onSendEmails,
}) => {
  const [emailSubject, setEmailSubject] = useState(
    "Your Open Mic Night performance at The Cola"
  );
  const [emailBody, setEmailBody] =
    useState(`Pass That: Open Mic Night is a monthly event that showcases the best talent in the St. Louis area. Performers have the opportunity to share their comedy in a happy and high environment. 

The event is held at The Cola in the Cherokee neighborhood. Performers are selected and given the opportunity to perform in front of a live audience. The event starts at 9:30 PM. Performers should arrive by 9 PM to check in. Performers will have 4 minutes to perform.

The Cola is located at 2834 Cherokee St. St. Louis, MO 63118 in the Cherokee neighborhood. The venue is a cannabis lounge that serves a variety of drinks and treats. The venue is 21+ and has a full bar. Medical card holders 18+ are welcome.`);

  const handleSend = async () => {
    await onSendEmails({ emailSubject, emailBody });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Confirm Email Send</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Selected Performers
          </Typography>
          <List>
            {selectedPerformers.map((performer) => (
              <ListItem key={performer.id}>
                <ListItemText
                  primary={performer.stageName || performer.name}
                  secondary={performer.email}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => onRemovePerformer(performer.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          Email Preview
        </Typography>
        <TextField
          fullWidth
          label="Subject"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Body"
          value={emailBody}
          onChange={(e) => setEmailBody(e.target.value)}
          multiline
          rows={8}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSend}
          variant="contained"
          color="primary"
          disabled={selectedPerformers.length === 0}
        >
          Send Emails
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailConfirmationModal;
