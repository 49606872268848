import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";

import { AppContext } from "../context/AppContext";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { CartContext } from "../context/CartContext";
import { centsToCurrency } from "../services/firebase";
import { timeToDateString } from "../services/utils";
import { useNavigate } from "react-router-dom";

const GiftPage = () => {
  const { addToCart } = useContext(CartContext);
  const { passTemplates, products } = useContext(AppContext);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [giftMessage, setGiftMessage] = useState("");

  // Filter out year passes and get merch products
  const giftPasses = passTemplates.filter((pass) => pass.type !== "year");
  const merchProducts = products["Merch"] || [];

  const addGiftToCart = (item) => {
    const giftItem = {
      ...item,
      isGift: true,
      giftMessage: giftMessage,
      giftDate: timeToDateString(new Date()),
    };
    addToCart(giftItem, [], giftMessage);
    setSnackbar({
      open: true,
      message: `${item.name} added to your gift cart!`,
    });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Where Buds Give Gifts
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Share the experience with friends and family
        </Typography>

        <TextField
          fullWidth
          multiline
          rows={3}
          label="Add a Gift Message"
          value={giftMessage}
          onChange={(e) => setGiftMessage(e.target.value)}
          sx={{ mb: 4 }}
          placeholder="Enter a personal message to be included with your gift..."
        />

        {merchProducts.length > 0 && (
          <>
            <Typography variant="h4" gutterBottom sx={{ mt: 6 }}>
              Cola Merchandise
            </Typography>
            <Grid container spacing={3}>
              {merchProducts.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.id}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      "&:hover": {
                        boxShadow: 6,
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.image}
                      alt={product.name}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {product.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        paragraph
                      >
                        {product.description}
                      </Typography>
                      <Typography variant="h6" color="primary">
                        {centsToCurrency(product.amount)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        startIcon={<CardGiftcardIcon />}
                        fullWidth
                        variant="contained"
                        onClick={() => addGiftToCart(product)}
                      >
                        Gift This Item
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
          Gift Passes
        </Typography>
        <Grid container spacing={3}>
          {giftPasses.map((pass) => (
            <Grid item xs={12} sm={6} md={4} key={pass.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  "&:hover": {
                    boxShadow: 6,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image="/images/day-pass.jpg"
                  alt={pass.name}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {pass.name}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    {pass.description}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    {centsToCurrency(pass.amount)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    startIcon={<CardGiftcardIcon />}
                    fullWidth
                    variant="contained"
                    onClick={() => addGiftToCart(pass)}
                  >
                    Gift This Pass
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
        action={
          <Button color="secondary" size="small" onClick={handleCheckout}>
            Checkout
          </Button>
        }
      />
    </Container>
  );
};

export default GiftPage;
