import * as Yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  List,
  ListItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

import Alert from "@mui/material/Alert";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Email from "@mui/icons-material/Email";
import Facebook from "@mui/icons-material/Facebook";
import Info from "@mui/icons-material/Info";
import Instagram from "@mui/icons-material/Instagram";
import Language from "@mui/icons-material/Language";
import Mic from "@mui/icons-material/Mic";
import Person from "@mui/icons-material/Person";
import SocialMetaTags from "../components/header/SocialMetaTags";
import YouTube from "@mui/icons-material/YouTube";
import { registerForOpenMic } from "../services/firebase";
import { useFormik } from "formik";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  stageName: Yup.string().required("Stage name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  instagram: Yup.string(),
  facebook: Yup.string(),
  tiktok: Yup.string(),
  youtube: Yup.string(),
  otherSocial: Yup.string(),
});

const TikTokIcon = ({ color = "action" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100%"
      height="100%"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const OpenMicSignup = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      stageName: "",
      email: "",
      instagram: "",
      facebook: "",
      tiktok: "",
      youtube: "",
      otherSocial: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        await registerForOpenMic(
          values.name,
          values.stageName,
          values.email,
          values.instagram,
          values.facebook,
          values.tiktok,
          values.youtube,
          values.otherSocial
        );
        setSubmitted(true);
        setSnackbar({
          open: true,
          message: "Successfully registered!",
          severity: "success",
        });
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Failed to register. Please try again.",
          severity: "error",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleSnackbarClose = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <>
      <SocialMetaTags
        title="Open Mic Signup"
        image="/images/open-mic.jpg"
        description="Sign up for the Open Mic Night at The Cola St. Louis"
      />

      <Box
        sx={{
          backgroundImage:
            "url('https://firebasestorage.googleapis.com/v0/b/manageyourclub-973b2.appspot.com/o/clubs%2Fcola-stl%2Fevents%2Fpass-that-open-mic%2Fpass-that-signup.jpg?alt=media&token=b22bae4d-7c09-4d05-b2bd-63c972220623')",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "300px",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          mb: 4,
          pr: 2,
        }}
      >
        <Typography
          variant="h6"
          component="h1"
          sx={{
            color: "white",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
            fontWeight: "bold",
            textAlign: "right",
          }}
        >
          Sign Up Form
        </Typography>
      </Box>
      <Container maxWidth="md">
        {submitted ? (
          <Paper elevation={3} sx={{ p: 4, mt: 3, textAlign: "center" }}>
            <CheckCircle color="success" sx={{ fontSize: 80, mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              You're In!
            </Typography>
            <Typography variant="body1" paragraph>
              Thanks for signing up for the Open Mic Night at The Cola! We've
              got your info and we'll email you if you make the list.
            </Typography>
            <Typography variant="body2" paragraph>
              Keep an eye on your inbox for more details and updates.
            </Typography>
          </Paper>
        ) : (
          <>
            <Paper
              elevation={3}
              sx={{ p: 4, mb: 4, backgroundColor: "#f8f4ff" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Info color="primary" sx={{ mr: 1 }} />
                Important Information
              </Typography>
              <Typography variant="body1" paragraph>
                Thanks for your interest in our Open Mic Night! Please read the
                rules below and fill out the form to request a spot.
              </Typography>
              <List>
                <ListItem>
                  <Mic sx={{ mr: 1 }} /> 10 lottery slots available online
                </ListItem>
                <ListItem>
                  <Mic sx={{ mr: 1 }} /> 5 in-person slots (arrive before 9:30
                  PM)
                </ListItem>
                <ListItem>
                  <Mic sx={{ mr: 1 }} /> 4-minute sets (exceeding = next month
                  ban)
                </ListItem>
                <ListItem>
                  <Mic sx={{ mr: 1 }} /> Check your email to see if you made the
                  list!
                </ListItem>
              </List>
            </Paper>
            <form onSubmit={formik.handleSubmit}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h5" gutterBottom>
                  Sign Up Form
                </Typography>
                <TextField
                  label="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{
                    startAdornment: <Person color="action" />,
                  }}
                />
                <TextField
                  label="Stage Name"
                  name="stageName"
                  value={formik.values.stageName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.stageName && Boolean(formik.errors.stageName)
                  }
                  helperText={
                    formik.touched.stageName && formik.errors.stageName
                  }
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{
                    startAdornment: <Mic color="action" />,
                  }}
                />
                <TextField
                  label="Email Address"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{
                    startAdornment: <Email color="action" />,
                  }}
                />
                <Divider sx={{ my: 3 }} />
                <Typography variant="h6" gutterBottom>
                  Social Media (Optional)
                </Typography>
                <TextField
                  label="Instagram"
                  name="instagram"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.instagram && Boolean(formik.errors.instagram)
                  }
                  helperText={
                    formik.touched.instagram && formik.errors.instagram
                  }
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: <Instagram color="action" />,
                  }}
                />
                <TextField
                  label="Facebook"
                  name="facebook"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.facebook && Boolean(formik.errors.facebook)
                  }
                  helperText={formik.touched.facebook && formik.errors.facebook}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: <Facebook color="action" />,
                  }}
                />
                <TextField
                  label="TikTok"
                  name="tiktok"
                  value={formik.values.tiktok}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.tiktok && Boolean(formik.errors.tiktok)}
                  helperText={formik.touched.tiktok && formik.errors.tiktok}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        <TikTokIcon color="rgba(0, 0, 0, 0.54)" />
                      </Box>
                    ),
                  }}
                />
                <TextField
                  label="YouTube"
                  name="youtube"
                  value={formik.values.youtube}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.youtube && Boolean(formik.errors.youtube)
                  }
                  helperText={formik.touched.youtube && formik.errors.youtube}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: <YouTube color="action" />,
                  }}
                />
                <TextField
                  label="Other Social Media"
                  name="otherSocial"
                  value={formik.values.otherSocial}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.otherSocial &&
                    Boolean(formik.errors.otherSocial)
                  }
                  helperText={
                    formik.touched.otherSocial && formik.errors.otherSocial
                  }
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: <Language color="action" />,
                  }}
                />
                <Box mt={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                    sx={{
                      backgroundColor: "#6a1b9a",
                      "&:hover": {
                        backgroundColor: "#4a148c",
                      },
                      py: 1.5,
                      fontSize: "1.1rem",
                    }}
                  >
                    {isSubmitting ? <CircularProgress size={24} /> : "Sign Up"}
                  </Button>
                </Box>
              </Paper>
            </form>
          </>
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default OpenMicSignup;
